import React from 'react';
import './Web.css';

function Web({ data }) {
  return (
    <div className="category">
      <h2>Web</h2>
      <div className="subcategories">
        {Object.keys(data).map(subcategory => (
          <div className="subcategory" key={subcategory}>
            <h3>{subcategory}</h3>
            <div className="items">
              {data[subcategory].map(item => (
                <div className="item" key={item.name}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <img src={item.logo} alt={`${item.name} logo`} />
                    <span className="item-name">{item.name}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Web;
