/* global adsbygoogle */
import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import Communication from './components/Communication';
import Worship from './components/Worship';
import Administration from './components/Administration';
import Outreach from './components/Outreach';
import Education from './components/Education';
import Content from './components/Content';
import Web from './components/Web';
import AI from './components/AI';  // Import the AI category component

function App() {
  const [data, setData] = useState(null);
  const adRef = useRef(null); // Create a ref to track the ad element

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    fetch('/churchTechData.json')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  useEffect(() => {
    // Initialize Google Ads only if the ad has not been already initialized
    const loadAd = () => {
      if (window.adsbygoogle && adRef.current && !adRef.current.getAttribute('data-ad-loaded')) {
        try {
          (adsbygoogle = window.adsbygoogle || []).push({});
          adRef.current.setAttribute('data-ad-loaded', 'true'); // Mark the ad as loaded
        } catch (error) {
          console.error("AdSense initialization error:", error);
        }
      }
    };

    loadAd(); // Load the ad when the component mounts

  }, []); // Empty dependency array to run only once on mount

  if (!data) return <div>Loading...</div>;

  return (
    <div className="App">
      <header className="App-header">
        <h1>Church Technology Landscape</h1>

        {/* AdSense Test Ad Slot - Use ref to track and prevent multiple initializations */}
        <ins ref={adRef} className="adsbygoogle"
             style={{ display: 'block', marginBottom: '20px' }}
             data-ad-client="ca-pub-5848898043759530"  // Replace with your actual AdSense publisher ID
             // Enables test ads for local and initial testing    data-ad-test="on"                        
             data-ad-format="auto"                    // Ensures ads format automatically without a specific slot
             data-full-width-responsive="true"></ins> {/* Makes the ad responsive */}
        <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
      </header>

      {/* Render category components */}
      <AI data={data.AI} />
      <Communication data={data.Communication} />
      <Worship data={data.Worship} />
      <Administration data={data.Administration} />
      <Outreach data={data.Outreach} />
      <Education data={data.Education} />
      <Content data={data.Content} />
      <Web data={data.Web} />

      <footer className="App-footer">
        &copy; {currentYear} <a href="https://www.digitalministrylabs.io/" target="_blank" rel="noopener noreferrer">Digital Ministry Labs</a>
        <a 
          href="https://digitalministrylabs.atlassian.net/servicedesk/customer/portal/4" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Feedback
        </a>
        <a href="https://logo.dev" alt="Logo API" target="_blank" rel="noopener noreferrer">Logos provided by Logo.dev</a>
      </footer>
    </div>
  );
}

export default App;
